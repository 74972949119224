import React from 'react';
import './App.css';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Main from "./Main";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main/>,
  },
]);

function App() {
  return (
    <div className="App-wrapper">
      <RouterProvider router={router}/>
    </div>
  );
}

export default App;
