import React, {ReactNode, useRef} from "react";
import {useResizeObserver} from 'usehooks-ts'

const VERTICAL_WIDTH = 600;

export function SectionWithSideLabel(props: {
    label: string,
    labelWidth: number,
    children: ReactNode
}) {
    const ref = useRef<HTMLDivElement>(null);
    const {width = 0} = useResizeObserver({
        ref,
        box: 'border-box',
    })

    let text = <div style={{
        padding: 10,
        flex: "1 0 200px"
    }}>
        {props.children}
    </div>;

    let contents: React.ReactElement;
    if (width < VERTICAL_WIDTH) {
        // Vertical layout
        contents = <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "stretch",
                margin: "10px 0",
                backgroundColor: "#53a2c7",
                borderRadius: "10px",
                height: "min-content"
            }}>
            <div style={{
                backgroundColor: "#213244",
                color: "#EEEEE9",
                borderRadius: "10px",
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                padding: 10,
                fontVariant: "small-caps",
                textAlign: "center",
            }}
                 key={"label-vertical"}>
                <h2 style={{
                    fontSize: "1.5em",
                    margin: 0,
                }}>{props.label}</h2>
            </div>
            {text}
        </div>
    } else {
        // Side-by-side layout
        contents = <div
            style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "stretch",
                margin: "10px 0",
                backgroundColor: "#53a2c7",
                borderRadius: "10px",
                height: "min-content"
            }}>
            <div style={{
                backgroundColor: "#213244",
                color: "#EEEEE9",
                borderRadius: "10px",
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                padding: 10,
                fontVariant: "small-caps",
                display: "flex",
                alignItems: "center",
                marginRight: 12,
                width: props.labelWidth,
                textAlign: "center",
            }}
                 key={"label-horizontal"}
            >
                <h2 style={{
                    fontSize: "1.5em",
                    margin: 0,
                    width: "100%"
                }}>{props.label}</h2>
            </div>
            {text}
        </div>;
    }
    return <div
        ref={ref}
        style={{
            width: "100%",
        }}>
        {contents}
    </div>
}